import { environment } from './environment';

export const regresUrl: string = 'https://reqres.in/api/';
export const endpoints = {
  administrator: {
    addOrganizationAdminShort: `${environment.baseApiUrl}administrator/addOrganizationAdminShort`,
    getAdministrator: `${environment.baseApiUrl}administrator/getAdministrator`,
    getAllAdministrators: `${environment.baseApiUrl}administrator/getAllAdministrators`,
    inactiveOrActiveOrganizationAdminShort: `${environment.baseApiUrl}administrator/inactiveOrActiveOrganizationAdminShort`,
    updateAdministrator: `${environment.baseApiUrl}administrator/updateAdministrator`,
  },
  authentication: {
    completeAccount: 'account/completeAccount',
    getAccount: 'account/getAccount',
    getPendingAccount: 'account/getPendingAccount',
    login: 'account/authenticate',
    recoveryPassword: 'account/recoveryPassword',
    resetPassword: 'account/resetPassword',
    addAccount: 'account/addAccount',
  },
  chime: {
    addTranscription: `${environment.baseApiUrl}Meeting/AddTranscription`,
    createAttendee: `${environment.baseApiUrl}Meeting/AddAttendee`,
    createMeeting: `${environment.baseApiUrl}Meeting/AddMeeting`,
    deleteAttendee: `${environment.baseApiUrl}Meeting/DeleteAttendee`,
    deleteMeeting: `${environment.baseApiUrl}Meeting/DeleteMeeting`,
    deleteTranscription: `${environment.baseApiUrl}Meeting/DeleteTranscription`,
    getAccessKey: `${environment.baseApiUrl}Meeting/GetAccessKey`,
    getAttendee: `${environment.baseApiUrl}Meeting/GetAttendee`,
    getMeeting: `${environment.baseApiUrl}Meeting/GetMeeting`,
    listAttendees: `${environment.baseApiUrl}Meeting/GetAllAttendees`,
    sendInviteByEmail: `${environment.baseApiUrl}Meeting/sendInviteByEmail`,
    sendInviteByWhatsApp: `${environment.baseApiUrl}Meeting/sendInviteByWhatsApp`,
  },
  clinicalReferences: {
    getAllIcdCodes: `${environment.baseApiUrl}clinicalReferences/getAllIcdCodes`,
  },
  common: {
    viaCep: 'https://viacep.com.br/ws/',
  },
  consultation: {
    addClinicalNotes: `${environment.baseApiUrl}consultation/addClinicalNotes`,
    addIcdCodes: `${environment.baseApiUrl}consultation/addIcdCodes`,
    addPatientFiles: `${environment.baseApiUrl}consultation/addPatientFiles`,
    addPatientPrescription: `${environment.baseApiUrl}consultation/addPatientPrescription`,
    deletePatientFile: `${environment.baseApiUrl}consultation/deletePatientFile`,
    getAllClinicalNotes: `${environment.baseApiUrl}consultation/getAllClinicalNotes`,
    getAllPatientFiles: `${environment.baseApiUrl}consultation/getAllPatientFiles`,
    getClinicalNotes: `${environment.baseApiUrl}consultation/getClinicalNotes`,
    getIcdCodes: `${environment.baseApiUrl}consultation/getIcdCodes`,
    getPrescriptionDocuments: `${environment.baseApiUrl}consultation/getPrescriptionDocuments`,
    updateClinicalNotes: `${environment.baseApiUrl}consultation/updateClinicalNotes`,
  },
  location: {
    cities: {
      getAll: `${environment.baseApiUrl}Location/GetAllCities`,
    },
    states: {
      getAll: `${environment.baseApiUrl}Location/GetAllStates`,
    },
  },
  mevo: {
    prescription: `${environment.mevoURL}prescricao/`,
  },
  meeting: {
    addGuest: `${environment.baseApiUrl}Meeting/AddGuest`,
    approveMeetingJoin: `${environment.baseApiUrl}Meeting/approveMeetingJoin`,
    createGuestKey: `${environment.baseApiUrl}Meeting/createGuestKey`,
    deleteAttendee: `${environment.baseApiUrl}Meeting/deleteAttendee`,
    requestMeetingJoin: `${environment.baseApiUrl}Meeting/requestMeetingJoin`,
  },
  n8n: {
    audioTranscription:
      'https://n8n.doubleit.com.br/webhook/audioTranscription',
    textTranscription: 'https://n8n.doubleit.com.br/webhook/textTranscription',
  },
  notification: {
    chatNotifications: `${environment.baseUrl}chatNotifications`,
    meetingNotifications: `${environment.baseUrl}meetingNotifications`,
    scheduleNotifications: `${environment.baseUrl}scheduleNotifications`,
  },
  openAi: {
    medicalChatStream: `${environment.baseApiUrl}openAI/medicalChatStream`,
    uploadFile: 'https://api.openai.com/v1/files',
  },
  organization: {
    add: `${environment.baseApiUrl}organization/getAllOrganizations`,
    addOrganization: `${environment.baseApiUrl}organization/AddOrganization`,
    delete: `${environment.baseApiUrl}organization/DeletePatient`,
    deleteAdmin: `${environment.baseApiUrl}organization/DeletePatient`,
    getAll: `${environment.baseApiUrl}organization/getAllOrganizations`,
    getBusinessModelMatrix: `${environment.baseApiUrl}organization/GetBusinessModelMatrix`,
    getOrganization: `${environment.baseApiUrl}organization/getOrganization`,
    getOrganizationDetails: `${environment.baseApiUrl}organization/getOrganizationDetails`,
    inactiveOrActiveOrganization: `${environment.baseApiUrl}organization/InactiveOrActiveOrganization`,
    updateOrganization: `${environment.baseApiUrl}organization/UpdateOrganization`,
  },
  patients: {
    add: `${environment.baseApiUrl}Patient/AddPatient`,
    addHealthData: `${environment.baseApiUrl}Patient/addHealthData`,
    addHistory: `${environment.baseApiUrl}Patient/AddPatientMedicalHistory`,
    addPatientShort: `${environment.baseApiUrl}Patient/addPatientShort`,
    delete: `${environment.baseApiUrl}Patient/DeletePatient`,
    getAll: `${environment.baseApiUrl}Patient/GetAllPatients`,
    getById: `${environment.baseApiUrl}Patient/GetPatient`,
    getHealthData: `${environment.baseApiUrl}Patient/getHealthData`,
    update: `${environment.baseApiUrl}Patient/UpdatePatient`,
    updateHealthData: `${environment.baseApiUrl}Patient/updateHealthData`,
  },
  professional: {
    add: `${environment.baseApiUrl}Professional/AddProfessional`,
    delete: `${environment.baseApiUrl}Professional/DeleteProfessional`,
    getAll: `${environment.baseApiUrl}Professional/GetAllProfessionals`,
    getAllBoards: `${environment.baseApiUrl}Professional/GetAllRegulatoryBoards`,
    getById: `${environment.baseApiUrl}Professional/GetProfessional`,
    specialties: {
      getAllSpecialties: `${environment.baseApiUrl}Professional/GetAllSpecialties`,
    },
    update: `${environment.baseApiUrl}Professional/UpdateProfessional`,
  },
  schedule: {
    addAppointment: `${environment.baseApiUrl}Schedule/AddAppointment`,
    addPatientNote: `${environment.baseApiUrl}Schedule/AddPatientNote`,
    addProfessionalSchedule: `${environment.baseApiUrl}Schedule/AddProfessionalSchedule`,
    deleteAppointment: `${environment.baseApiUrl}Schedule/DeleteAppointment`,
    getAllPatientNotes: `${environment.baseApiUrl}Schedule/GetAllPatientNotes`,
    getAppointmentsHistory: `${environment.baseApiUrl}Schedule/GetAppointmentsHistory`,
    getAppointmentDetails: `${environment.baseApiUrl}Schedule/GetAppointmentDetails`,
    getAvailableScheduleProfessional: `${environment.baseApiUrl}Schedule/GetAvailableScheduleProfessional`,
    getProfessionalSchedule: `${environment.baseApiUrl}Schedule/GetProfessionalSchedule`,
    getUpcomingAppointments: `${environment.baseApiUrl}Schedule/GetUpcomingAppointments`,
    updateAppointmentStatus: `${environment.baseApiUrl}Schedule/UpdateAppointmentStatus`,
    updateProfessionalSchedule: `${environment.baseApiUrl}Schedule/UpdateProfessionalSchedule`,
  },
  scheduler: {
    add: `${environment.baseApiUrl}Scheduler/AddScheduler`,
    delete: `${environment.baseApiUrl}Scheduler/DeleteScheduler`,
    getAll: `${environment.baseApiUrl}Scheduler/GetAllSchedulers`,
    getById: `${environment.baseApiUrl}Scheduler/GetScheduler`,
    update: `${environment.baseApiUrl}Scheduler/UpdateScheduler`,
  },
  storage: {
    delete: `${environment.baseApiUrl}storage/delete`,
    moveToUserDocuments: `${environment.baseApiUrl}storage/moveToUserDocuments`,
    upload: `${environment.baseApiUrl}storage/upload`,
    uploadTemporary: `${environment.baseApiUrl}storage/uploadTemporary`,
  },
  termsOfUse: {
    acceptTermsOfUse: `${environment.baseApiUrl}termsOfUse/acceptTermsOfUse`,
    getTermsOfUse: `${environment.baseApiUrl}termsOfUse/getTermsOfUse`,
  },
  users: {
    getAllUsers: `${environment.baseApiUrl}user/getAllUsers`,
  },
};
