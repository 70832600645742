import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, catchError, concatAll, throwError } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

export const requestInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  //#region injection
  const notification = inject(NotificationService);
  const _authService = inject(AuthService);
  const _spinnerService = inject(NgxSpinnerService);
  //#endregion

  //#region data
  const token = _authService.getToken() || '';
  const secretKey = _authService.getSecretKey() || '';
  const viacepDomain = 'viacep.com.br';
  const mevoDomain = 'emr-homolog.nexodata.com.br';
  let clonedRequest = req;
  //#endregion

  //#region logical
  if (
    !req.url.includes(viacepDomain) &&
    !req.url.includes(mevoDomain) &&
    !req.url.includes('resetPassword') &&
    !req.url.includes('getPendingAccount') &&
    !req.url.includes('addAccount') &&
    !req.url.includes('completeAccount')
  ) {
    clonedRequest = req.clone({
      headers: req.headers
        .set('Authorization', 'Bearer ' + token)
        .set('Secret-Key', secretKey),
    });
  }

  if (req.url.includes(mevoDomain)) {
    clonedRequest = req.clone({
      headers: req.headers
        .set('Authorization', 'Basic ZG91YmxlaXQ6RjZoTmdqeEZZSHBvWGg=')
        .set('Secret-Key', secretKey),
    });
  }

  if (
    req.url.includes('getPendingAccount') ||
    req.url.includes('addAccount') ||
    req.url.includes('completeAccount')
  ) {
    clonedRequest = req.clone({
      headers: req.headers.set('Secret-Key', secretKey),
    });
  }

  if (req.url.includes('resetPassword')) {
    clonedRequest = req.clone({
      headers: req.headers.set('Recovery-Key', secretKey),
    });
  }

  //#endregion
  return next(clonedRequest).pipe(
    catchError((error: HttpErrorResponse) => {
      _spinnerService.hide();
      if (error.status === 422) {
        if (error.error && Array.isArray(error.error)) {
          error.error.forEach((err) => {
            if (err.message) {
              notification.toatsError('Atenção', err.message);
            }
          });
        }
        return throwError(() => new Error('Erro de envio'));
      } else if (error.status === 401) {
        notification.toatsError(
          'Não autorizado',
          'Por favor, faça login novamente.',
        );
        _authService.logOut();
        return throwError(() => new Error('Não autorizado'));
      } else if (error.status === 400) {
        // notification.toatsError('Atenção', error.error.message);
        return throwError(() => new Error('Não autorizado'));
      }
      notification.toatsError(
        'Erro no servidor',
        'Por favor, tente novamente mais tarde.',
      );
      return throwError(() => new Error('Erro no servidor'));
    }),
  );
};
