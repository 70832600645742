import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { environment } from 'src/environments/environment';

export const requestInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  //#region injection
  const notification = inject(NotificationService);
  const _authService = inject(AuthService);
  const _spinnerService = inject(NgxSpinnerService);
  //#endregion

  //#region data
  const token = _authService.getToken() || '';
  const secretKey = _authService.getSecretKey() || '';
  const viacepDomain = 'viacep.com.br';
  const mevoDomain = new URL(environment.mevoURL).hostname;
  const mevoKey = environment.mevoKey;
  let clonedRequest = req;
  //#endregion

  //#region logical
  if (
    !req.url.includes(viacepDomain) &&
    !req.url.includes(mevoDomain) &&
    !req.url.includes('resetPassword') &&
    !req.url.includes('getPendingAccount') &&
    !req.url.includes('addAccount') &&
    !req.url.includes('completeAccount')
  ) {
    clonedRequest = req.clone({
      headers: req.headers
        .set('Authorization', 'Bearer ' + token)
        .set('Secret-Key', secretKey),
    });
  }

  if (req.url.includes(mevoDomain)) {
    clonedRequest = req.clone({
      headers: req.headers
        .set('Authorization', `Basic ${mevoKey}`)
        .set('Secret-Key', secretKey),
    });
  }

  if (
    req.url.includes('getPendingAccount') ||
    req.url.includes('addAccount') ||
    req.url.includes('completeAccount')
  ) {
    clonedRequest = req.clone({
      headers: req.headers.set('Secret-Key', secretKey),
    });
  }

  if (req.url.includes('resetPassword')) {
    clonedRequest = req.clone({
      headers: req.headers.set('Recovery-Key', secretKey),
    });
  }

  //#endregion
  return next(clonedRequest).pipe(
    catchError((error: HttpErrorResponse) => {
      _spinnerService.hide();
      const handleErrors = {
        422: () => {
          (error.error as { message: string }[])?.forEach?.((err) => {
            if (err.message) notification.toastError('Atenção', err.message);
          });
          return throwError(() => new Error('Erro de envio'));
        },
        401: () => {
          const isMevoDomain = req.url.includes(mevoDomain);
          !isMevoDomain &&
            notification.toastError(
              'Não autorizado',
              'Por favor, faça login novamente.',
            );
          !isMevoDomain && _authService.logOut();
          return throwError(() => new Error('Não autorizado'));
        },
        400: () => throwError(() => new Error('Não autorizado')),
        default: () => {
          notification.toastError(
            'Erro no servidor',
            'Por favor, tente novamente mais tarde.',
          );
          return throwError(() => new Error('Erro no servidor'));
        },
      };
      const handler =
        handleErrors[error.status as 422 | 401 | 400] || handleErrors.default;
      return handler();
    }),
  );
};
