import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from '@angular/router';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideNgxMask } from 'ngx-mask';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatNativeDateModule } from '@angular/material/core';
import { routes } from './app.routes';
import { requestInterceptor } from './core/services/interceptor/interceptor.service';
import { QuillModule } from 'ngx-quill';
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(PreloadAllModules)), // Precarrega todos os chunks
    provideHttpClient(withFetch(), withInterceptors([requestInterceptor])),
    provideClientHydration(),
    provideNgxMask(),
    provideAnimations(),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      NgxSpinnerModule,
      MatNativeDateModule,
      QuillModule.forRoot(),
    ),
    provideAnimationsAsync(),
  ],
};
