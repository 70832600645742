import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authentication = inject(AuthService);
  const isAuthentication = authentication.getAuthentication();
  if (!isAuthentication) return false;

  return true;
};
