import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [NgxSpinnerModule],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
})
export class SpinnerComponent implements OnInit, OnChanges {
  @Input() loading: boolean = false;

  public spinnerService = inject(NgxSpinnerService);

  ngOnInit(): void {
    this.setSpinnerState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSpinnerState();
  }

  private setSpinnerState(): void {
    this.loading ? this.spinnerService.show() : this.spinnerService.hide();
  }
}
