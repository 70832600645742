import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private _http = inject(HttpClient);

  public get<T>(endpoint: string, params?: HttpParams): Observable<T> {
    return this._http.get<T>(`${endpoint}`, { params });
  }

  public getById<T>(endpoint: string, params: HttpParams): Observable<T> {
    return this._http.get<T>(`${endpoint}`, { params });
  }

  public post<T>(endpoint: string, data: any): Observable<T> {
    return this._http.post<T>(`${endpoint}`, data);
  }

  public update<T>(endpoint: string, data: any): Observable<T> {
    return this._http.put<T>(`${endpoint}`, data);
  }

  public delete<T>(endpoint: string, params?: HttpParams): Observable<any> {
    return this._http.delete<T>(`${endpoint}`, {
      params,
    });
  }
}
