import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  //#region methods
  public async success(
    title: string,
    message: string,
    timer: number = 3000,
  ): Promise<void> {
    Swal.fire({
      title: title,
      text: message,
      icon: 'success',
      showConfirmButton: true,
      timer: timer,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  }

  public async error(title: string, message: string): Promise<void> {
    Swal.fire({
      title: title,
      text: message,
      icon: 'error',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  }

  public async toastSuccess(
    title: string,
    message: string,
    timer: number = 3000,
  ): Promise<void> {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: timer,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: `<span style="font-size: 18px">${title}</span>`,
      html: `<span class="swal2-html" style="font-size: 16px">${message}</span>`,
    });
  }

  public toastError(
    title: string,
    message: string,
    timer: number = 3000,
  ): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: timer,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'error',
      title: `<span style="font-size: 18px">${title}</span>`,
      html: `<span class="swal2-html" style="font-size: 16px">${message}</span>`,
    });
  }

  public async confirmToast(
    title: string,
    message: string,
    timer: number = 3000,
  ): Promise<boolean> {
    const result = await Swal.fire({
      title: `<span style="font-size: 18px">${title}</span>`,
      html: `<span class="swal2-html" style="font-size: 16px">${message}</span>`,
      icon: 'warning',
      position: 'top-end',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      timer: timer,
      timerProgressBar: true,
      width: '300px', // Ajuste o tamanho conforme necessário
      allowOutsideClick: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    return result.isConfirmed;
  }
  //#endregion
}
