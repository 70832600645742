import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import {
  TAuthenticationResponse,
  TCompleteAccountParams,
  TPendingUserResponse,
  TSettings,
  TTermOfUse,
  TUserData,
} from '../../models/auth.model';
import { RequestService } from '../requests/request.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //#region injection
  public _requestService = inject(RequestService);
  public router = inject(Router);
  private secretKey: string = '';
  //#endregion

  //#region data
  public isAuthenticated = new BehaviorSubject<boolean>(false);
  //#endregion

  //#region methods
  public getAuthentication(): boolean {
    const authentication = this.getToken();

    if (!authentication) {
      this.isAuthenticated.next(false);
      this.router.navigateByUrl('/auth/login');
      return false;
    }

    this.isAuthenticated.next(true);
    return true;
  }

  public setToken(keep: boolean, token: string): void {
    keep
      ? localStorage.setItem('faytoken', token)
      : sessionStorage.setItem('faytoken', token);
  }

  public getToken() {
    return (
      localStorage.getItem('faytoken') || sessionStorage.getItem('faytoken')
    );
  }

  public getLoggedUser() {
    const token = this.getToken();
    if (token) {
      return jwtDecode(token);
    }
    return undefined;
  }

  public getUserData(): TUserData {
    return {
      id: localStorage.getItem('userId') || undefined,
      name: localStorage.getItem('name') || undefined,
      photoUrl: localStorage.getItem('photoUrl') || undefined,
      role: localStorage.getItem('role') || undefined,
    };
  }
  public getConfiguration() {
    const configuration = localStorage.getItem('configuration');
    return configuration ? JSON.parse(configuration) : undefined;
  }

  public removeToken() {
    localStorage.removeItem('faytoken');
    sessionStorage.removeItem('faytoken');
  }

  public async login(data: any): Promise<TAuthenticationResponse> {
    return await lastValueFrom(
      this._requestService.post(
        `${environment.baseApiUrl}${endpoints.authentication.login}`,
        data,
      ),
    );
  }

  public async recorveryPassword(
    email: string,
  ): Promise<TAuthenticationResponse> {
    return await lastValueFrom(
      this._requestService.update(
        `${environment.baseApiUrl}${endpoints.authentication.recoveryPassword}`,
        { login: email },
      ),
    );
  }

  public async getPendingAccount(): Promise<TPendingUserResponse> {
    return await lastValueFrom(
      this._requestService.get(
        `${environment.baseApiUrl}${endpoints.authentication.getPendingAccount}`,
      ),
    );
  }

  public async completeAccount(data: TCompleteAccountParams): Promise<void> {
    return await lastValueFrom(
      this._requestService.update(
        `${environment.baseApiUrl}${endpoints.authentication.completeAccount}`,
        data,
      ),
    );
  }

  public async resetPassword(
    newPassword: string,
    confirmPassword: string,
    recoveryKey: string,
  ): Promise<TAuthenticationResponse> {
    return await lastValueFrom(
      this._requestService.update(
        `${environment.baseApiUrl}${endpoints.authentication.resetPassword}`,
        { newPassword, confirmPassword },
      ),
    );
  }
  public async addAccount(data: any): Promise<TSettings> {
    return await lastValueFrom(
      this._requestService.post(
        `${environment.baseApiUrl}${endpoints.authentication.addAccount}`,
        data,
      ),
    );
  }

  public async getAccount(): Promise<TSettings> {
    return await lastValueFrom(
      this._requestService.get(
        `${environment.baseApiUrl}${endpoints.authentication.getAccount}`,
      ),
    );
  }

  public logOut() {
    this.isAuthenticated.next(false);
    this.removeToken();
    this.router.navigateByUrl('/auth/login');
  }

  public setSecretKey(token: string): void {
    this.secretKey = token;
  }

  public getSecretKey(): string {
    return this.secretKey;
  }

  public async getTermOfUse(): Promise<TTermOfUse> {
    return await lastValueFrom(
      this._requestService.get(`${endpoints.termsOfUse.getTermsOfUse}`),
    );
  }

  public async acceptTermsOfUse(termsOfUseId: string) {
    return await lastValueFrom(
      this._requestService.post(`${endpoints.termsOfUse.acceptTermsOfUse}`, {
        termsOfUseId,
      }),
    );
  }
  //#endregion
}
