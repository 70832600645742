import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'guest-area',
    loadChildren: () =>
      import('./pages/guest-area/guest-area.routes').then(
        (c) => c.GUEST_ROUTER,
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth-area/auth.routes').then((c) => c.AUTH_ROUTES),
  },
  {
    path: '',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./pages/pages.routes').then((c) => c.PAGES_ROUTER),
  },

  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];
